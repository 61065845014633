import { Space, Alert } from 'antd';
import { Title, Paragraph, Text, Post, PostList, PostFooter, Link } from '~components';
import * as React from 'react';
export default {
  Space,
  Alert,
  Title,
  Paragraph,
  Text,
  Post,
  PostList,
  PostFooter,
  Link,
  React
};