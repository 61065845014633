import { SyntaxHighlighter, Link, KnowledgePill, Title, Text, PostList } from '~components';
import * as React from 'react';
export default {
  SyntaxHighlighter,
  Link,
  KnowledgePill,
  Title,
  Text,
  PostList,
  React
};