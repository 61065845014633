import { Link as GatsbyLink } from 'gatsby';
import { Text, Title, Paragraph, Link, Todo, Post, PostCard, PostFooter } from '~components';
import * as React from 'react';
export default {
  GatsbyLink,
  Text,
  Title,
  Paragraph,
  Link,
  Todo,
  Post,
  PostCard,
  PostFooter,
  React
};