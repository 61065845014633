import { Space, Alert } from 'antd';
import { Link as GatsbyLink } from 'gatsby';
import { Title, Paragraph, Text, Post, PostList, PostFooter, SyntaxHighlighter, Link, Box, ScrollToTop } from '~components';
import * as React from 'react';
export default {
  Space,
  Alert,
  GatsbyLink,
  Title,
  Paragraph,
  Text,
  Post,
  PostList,
  PostFooter,
  SyntaxHighlighter,
  Link,
  Box,
  ScrollToTop,
  React
};