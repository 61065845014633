import { Space, Alert } from 'antd';
import { TerminalImage, GuiVsTerminalImage, PwdImage, LsImage, CdImage, MkdirImage, OtherCommandsImage } from '~assets';
import { Title, Paragraph, Text, Post, PostList, PostCard, PostFooter, SyntaxHighlighter, Link, Box, Image } from '~components';
import * as React from 'react';
export default {
  Space,
  Alert,
  TerminalImage,
  GuiVsTerminalImage,
  PwdImage,
  LsImage,
  CdImage,
  MkdirImage,
  OtherCommandsImage,
  Title,
  Paragraph,
  Text,
  Post,
  PostList,
  PostCard,
  PostFooter,
  SyntaxHighlighter,
  Link,
  Box,
  Image,
  React
};