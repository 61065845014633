import { SyntaxHighlighter, Link, KnowledgePill, Text, Title, Paragraph, PostList } from '~components';
import * as React from 'react';
export default {
  SyntaxHighlighter,
  Link,
  KnowledgePill,
  Text,
  Title,
  Paragraph,
  PostList,
  React
};