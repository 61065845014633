import { SyntaxHighlighter, Link, KnowledgePill, Text, Title, PostList, Paragraph } from '~components';
import * as React from 'react';
export default {
  SyntaxHighlighter,
  Link,
  KnowledgePill,
  Text,
  Title,
  PostList,
  Paragraph,
  React
};