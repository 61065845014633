// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layout-blog-layout-js": () => import("./../../../src/components/layout/BlogLayout.js" /* webpackChunkName: "component---src-components-layout-blog-layout-js" */),
  "component---src-components-layout-projects-layout-js": () => import("./../../../src/components/layout/ProjectsLayout.js" /* webpackChunkName: "component---src-components-layout-projects-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-feedback-poll-js": () => import("./../../../src/pages/feedback-poll.js" /* webpackChunkName: "component---src-pages-feedback-poll-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-pills-js": () => import("./../../../src/pages/pills.js" /* webpackChunkName: "component---src-pages-pills-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-reach-out-js": () => import("./../../../src/pages/reach-out.js" /* webpackChunkName: "component---src-pages-reach-out-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

