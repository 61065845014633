import { Row, Col } from 'antd';
import { Link as GatsbyLink } from 'gatsby';
import { Text, Link, Button, Project } from '~components';
import * as React from 'react';
export default {
  Row,
  Col,
  GatsbyLink,
  Text,
  Link,
  Button,
  Project,
  React
};